<template>
  <section class="video-inner">
    <div class="wrapper">
      <h2 v-if="title" class="text-subheader">{{ title }}</h2>
      <div class="video">
        <transition-group name="fade">
          <div v-if="!isPlay" class="video__poster">
            <UIPictureTag
              :image-webp="`${url}${image.webp}`"
              :image-original="`${url}${image.source}`"
              :image-seo="imageSeo"
            />
            <div class="video__icon" @click="isPlay = true"></div>
          </div>

          <iframe
            v-else
            class="video__iframe"
            :src="`${link}?rel=0`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </transition-group>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ImageItem, ImageSEO } from '~/types/pages';
import { ref, useRuntimeConfig } from '#imports';
import './styles.scss';

interface Props {
  link: string;
  image: ImageItem;
  imageSeo: ImageSEO;
  title: string;
}

defineProps<Props>();

const url = useRuntimeConfig().public.siteUrl;

const isPlay = ref(false);
</script>

<style scoped></style>
